import { types as T } from "../actions/action_types";
// import { setIn } from "../common/utils";

// let results = [];

const initialState = {
  route: null,
  isLoading: 0,
  isLoggedIn: false,
  adminInfo: {},
  dashboard_statistics: {},
  districts: [],
  talukas: [],
  cities: [],
  wards: [],
  societies: [],
  core_members: [],
  kdvs_members: [],
  total_kdvs_members: 0,
  social_media_posts: [],
  upcoming_birthday: [],
  academic_batchs: [],
  events: [],
  jobs: [],
};

const requestReg = /_REQUEST/;
const resultReg = /_SUCCESS|_FAIL/;

export default function reducer(state = initialState, action) {
  const isRequest =
    requestReg.test(action.type) &&
    action.type.indexOf("LOAD_USER_INFO") === -1;
  const isResult =
    resultReg.test(action.type) && action.type.indexOf("LOAD_USER_INFO") === -1;
  // set loading state for if any api calling
  if (isRequest) {
    state = { ...state, isLoading: state.isLoading + 1 };
  } else if (isResult) {
    let load = state.isLoading - 1;
    if (load < 0) load = 0;
    state = { ...state, isLoading: load };
  }
  // reducer action and set according in to reducer state
  switch (action.type) {
    case T.SET_ROUTE:
      return {
        ...state,
        route: action.data,
      };

    case T.SET_ADMININFO:
      return {
        ...state,
        isLoggedIn: true,
        adminInfo: action.data,
      };

    case T.GET_DISTRICT_SUCCESS:
      return {
        ...state,
        districts: action.data?.data || [],
      };

    case T.GET_TALUKA_SUCCESS:
      return {
        ...state,
        talukas: action.data?.data || [],
      };

    case T.GET_CITY_SUCCESS:
      return {
        ...state,
        cities: action.data?.data || [],
      };

    case T.GET_WARD_SUCCESS:
      return {
        ...state,
        wards: action.data?.data || [],
      };

    case T.GET_SOCIETY_SUCCESS:
      return {
        ...state,
        societies: action.data?.data || [],
      };

    case T.LIST_CORE_MEMBER_SUCCESS:
      return {
        ...state,
        core_members: action.data?.data || [],
      };

    case T.LIST_KDVS_MEMBER_SUCCESS:
      return {
        ...state,
        kdvs_members: action.data?.data || [],
        total_kdvs_members: action.data?.total || 0,
      };

    case T.LIST_SOCIAL_MEDIA_POSTS_SUCCESS:
      return {
        ...state,
        social_media_posts: action.data?.data || [],
      };

    case T.LIST_UPCOMING_BIRTHDAY_SUCCESS:
      return {
        ...state,
        upcoming_birthday: action.data?.data || [],
      };

    case T.LIST_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.data?.data || [],
      };

    case T.LIST_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.data?.data || [],
      };

    case T.LIST_ACADEMIC_BATCHS_SUCCESS:
      return {
        ...state,
        academic_batchs: action.data?.data || [],
      };

    case T.GET_DASHBOARD_STATISTICS_SUCCESS:
      return {
        ...state,
        dashboard_statistics: action.data?.data || {},
      };

    case T.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
