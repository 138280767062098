import { types as T, make3 } from "./action_types";
import * as API from "../services/api";

// set route path
export function setRoute(data) {
  return {
    type: T.SET_ROUTE,
    data,
  };
}

// Login Admin
export function loginAdmin(data) {
  return {
    types: make3("LOGIN"),
    promise: () => {
      return API.login(data).then((result) => result);
    },
  };
}

// Set Admin info
export function setAdminInfo(data) {
  return {
    type: T.SET_ADMININFO,
    data,
  };
}

export function logout() {
  API.logout();
  return {
    type: T.LOGOUT,
  };
}

export const listDashboardStatistics = () => ({
  types: make3("GET_DASHBOARD_STATISTICS"),
  promise: () => API.getDashboardStatistics().then((result) => result),
});

export const listDistrict = () => ({
  types: make3("GET_DISTRICT"),
  promise: () => API.getDistrict().then((result) => result),
});

export const listTaluka = () => ({
  types: make3("GET_TALUKA"),
  promise: () => API.getTaluka().then((result) => result),
});

export const listCity = () => ({
  types: make3("GET_CITY"),
  promise: () => API.getCity().then((result) => result),
});

export const listWard = () => ({
  types: make3("GET_WARD"),
  promise: () => API.getWard().then((result) => result),
});

export const listSociety = () => ({
  types: make3("GET_SOCIETY"),
  promise: () => API.getSociety().then((result) => result),
});

export const saveNewCity = (data) => ({
  types: make3("SAVE_NEW_CITY"),
  promise: () => API.postNewCity(data).then((result) => result),
});

export const saveNewWard = (data) => ({
  types: make3("SAVE_NEW_WARD"),
  promise: () => API.postNewWard(data).then((result) => result),
});

export const saveNewSociety = (data) => ({
  types: make3("SAVE_NEW_SOCIETY"),
  promise: () => API.postNewSociety(data).then((result) => result),
});

export const deleteCity = (id) => ({
  types: make3("DELETE_CITY"),
  promise: () => API.deleteCity(id).then((result) => result),
});

export const deleteWard = (id) => ({
  types: make3("DELETE_WARD"),
  promise: () => API.deleteWard(id).then((result) => result),
});

export const deleteSociety = (id) => ({
  types: make3("DELETE_SOCIETY"),
  promise: () => API.deleteSociety(id).then((result) => result),
});

/**************************** Core member ****************************/

export const resetUserPassword = (id) => ({
  types: make3("RESET_USER_PASSWORD"),
  promise: () => API.resetCoreMemberPassword(id).then((result) => result),
});

export const saveCoreMember = (data) => ({
  types: make3("ADD_CORE_MEMBER"),
  promise: () => API.postCoreMember(data).then((result) => result),
});

export const editCoreMember = (id, data) => ({
  types: make3("EDIT_CORE_MEMBER"),
  promise: () => API.updateCoreMember(id, data).then((result) => result),
});

export const listCoreMember = (data = {}) => ({
  types: make3("LIST_CORE_MEMBER"),
  promise: () => API.getCoreMembers(data).then((result) => result),
});

export const viewCoreMember = (id) => ({
  types: make3("VIEW_CORE_MEMBER"),
  promise: () => API.getCoreMember(id).then((result) => result),
});

export const updateCoreMemberStatus = (id, data) => ({
  types: make3("UPDATE_CORE_MEMBER_STATUS"),
  promise: () => API.updateCoreMemberStatus(id, data).then((result) => result),
});

/**********************************************************************************/

/**************************** Volunteer member ****************************/

export const listVolunteerMember = (data = {}) => ({
  types: make3("LIST_KDVS_MEMBER"),
  promise: () => API.getVolunteerMembers(data).then((result) => result),
});

export const removeVolunteerMember = (id) => ({
  types: make3("REMOVE_KDVS_MEMBER"),
  promise: () => API.deleteVolunteerMember(id).then((result) => result),
});

/**********************************************************************************/

/**************************** Upcoming Birthday ****************************/
export const listUpcomingBirthday = (data) => ({
  types: make3("LIST_UPCOMING_BIRTHDAY"),
  promise: () => API.getUpcomingBirthday(data).then((result) => result),
});
/**********************************************************************************/

/**************************** Social Media Post ****************************/
export const listSocialMediaPost = (data) => ({
  types: make3("LIST_SOCIAL_MEDIA_POSTS"),
  promise: () => API.getSocialMediaPosts(data).then((result) => result),
});

export const viewSocialMediaPost = (id) => ({
  types: make3("VIEW_SOCIAL_MEDIA_POST"),
  promise: () => API.getSocialMediaPost(id).then((result) => result),
});

export const saveSocialMediaPost = (data) => ({
  types: make3("SAVE_SOCIAL_MEDIA_POST"),
  promise: () => API.postSocialMediaPost(data).then((result) => result),
});

export const editSocialMediaPost = (id, data) => ({
  types: make3("EDIT_SOCIAL_MEDIA_POST"),
  promise: () => API.updateSocialMediaPost(id, data).then((result) => result),
});

export const removeSocialMediaPost = (id) => ({
  types: make3("DELETE_SOCIAL_MEDIA_POST"),
  promise: () => API.deleteSocialMediaPost(id).then((result) => result),
});
/**********************************************************************************/

/**************************** Events ****************************/
export const listEvent = (data) => ({
  types: make3("LIST_EVENTS"),
  promise: () => API.getEvents(data).then((result) => result),
});

export const viewEvent = (id) => ({
  types: make3("VIEW_EVENT"),
  promise: () => API.getEvent(id).then((result) => result),
});

export const saveEvent = (data) => ({
  types: make3("SAVE_EVENT"),
  promise: () => API.postEvent(data).then((result) => result),
});

export const editEvent = (id, data) => ({
  types: make3("EDIT_EVENT"),
  promise: () => API.updateEvent(id, data).then((result) => result),
});

export const removeEvent = (id) => ({
  types: make3("DELETE_EVENT"),
  promise: () => API.deleteEvent(id).then((result) => result),
});
/**********************************************************************************/

/**************************** Jobs ****************************/
export const listJob = (data) => ({
  types: make3("LIST_JOBS"),
  promise: () => API.getJobs(data).then((result) => result),
});

export const viewJob = (id) => ({
  types: make3("VIEW_JOB"),
  promise: () => API.getJob(id).then((result) => result),
});

export const saveJob = (data) => ({
  types: make3("SAVE_JOB"),
  promise: () => API.postJob(data).then((result) => result),
});

export const editJob = (id, data) => ({
  types: make3("EDIT_JOB"),
  promise: () => API.updateJob(id, data).then((result) => result),
});

export const removeJob = (id) => ({
  types: make3("DELETE_JOB"),
  promise: () => API.deleteJob(id).then((result) => result),
});
/**********************************************************************************/

/**************************** Academic Batch Post ****************************/
export const listAcademicBatch = (data) => ({
  types: make3("LIST_ACADEMIC_BATCHS"),
  promise: () => API.getAcademicBatchs(data).then((result) => result),
});

export const viewAcademicBatch = (id) => ({
  types: make3("VIEW_ACADEMIC_BATCH"),
  promise: () => API.getAcademicBatch(id).then((result) => result),
});

export const saveAcademicBatch = (data) => ({
  types: make3("SAVE_ACADEMIC_BATCH"),
  promise: () => API.postAcademicBatch(data).then((result) => result),
});

export const editAcademicBatch = (id, data) => ({
  types: make3("EDIT_ACADEMIC_BATCH"),
  promise: () => API.updateAcademicBatch(id, data).then((result) => result),
});

export const removeAcademicBatch = (id) => ({
  types: make3("DELETE_ACADEMIC_BATCH"),
  promise: () => API.deleteAcademicBatch(id).then((result) => result),
});
/**********************************************************************************/
