import LoginPage from "../containers/auth/index";
import NotFoundPage from "../containers/404/index";
import MembersPage from "../containers/members/index";
import DashboardPage from "../containers/dashboard/index";

import CoreMembersPage from "../containers/core-members";
import ManageCoreMemberPage from "../containers/core-members/form";
import DataMasterPage from "../containers/data-masters/form";

import SocialMediaPostPage from "../containers/social-media-post";
import ManageSocialMediaPostPage from "../containers/social-media-post/form";

import EventsPage from "../containers/events";
import ManageEventPage from "../containers/events/form";

import JobsPage from "../containers/jobs";
import ManageJobPage from "../containers/jobs/form";

import AcademicBatchsPage from "../containers/academic-batchs";
import ManageAcademicBatchPage from "../containers/academic-batchs/form";

import UpcomingBirthdayPage from "../containers/upcoming-birthday";

// routes for declare new pages
export const protectedRoutes = [
  { path: "/admin/dashboard", component: DashboardPage, exact: true },
  { path: "/admin/kdvs-members", component: MembersPage, exact: true },
  { path: "/admin/core-members", component: CoreMembersPage, exact: true },
  {
    path: "/admin/core-members/create",
    component: ManageCoreMemberPage,
    exact: true,
  },
  {
    path: "/admin/core-members/:id",
    component: ManageCoreMemberPage,
    exact: true,
  },

  {
    path: "/admin/academic-batchs",
    component: AcademicBatchsPage,
    exact: true,
  },
  {
    path: "/admin/academic-batch/create",
    component: ManageAcademicBatchPage,
    exact: true,
  },
  {
    path: "/admin/academic-batch/:id",
    component: ManageAcademicBatchPage,
    exact: true,
  },

  {
    path: "/admin/social-media-posts",
    component: SocialMediaPostPage,
    exact: true,
  },
  {
    path: "/admin/social-media-post/create",
    component: ManageSocialMediaPostPage,
    exact: true,
  },
  {
    path: "/admin/social-media-post/:id",
    component: ManageSocialMediaPostPage,
    exact: true,
  },

  {
    path: "/admin/events",
    component: EventsPage,
    exact: true,
  },
  {
    path: "/admin/event/create",
    component: ManageEventPage,
    exact: true,
  },
  {
    path: "/admin/event/:id",
    component: ManageEventPage,
    exact: true,
  },

  {
    path: "/admin/jobs",
    component: JobsPage,
    exact: true,
  },
  {
    path: "/admin/job/create",
    component: ManageJobPage,
    exact: true,
  },
  {
    path: "/admin/job/:id",
    component: ManageJobPage,
    exact: true,
  },

  {
    path: "/admin/upcoming-birthday",
    component: UpcomingBirthdayPage,
    exact: true,
  },
  { path: "/admin/data-master", component: DataMasterPage, exact: true },
  { path: "/", component: DashboardPage, exact: true },
];

export const publicRoutes = [
  { path: "/login", component: LoginPage, exact: true },
  { path: "/", component: LoginPage, exact: true },
  { component: NotFoundPage },
];
