import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { useHistory, withRouter } from "react-router-dom";
import { Button, Card, Col, Form, Input, Row } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
import { RequiredRule } from "../../common/helper";
import { notifyError } from "../../common/notification";

const ManageJobPage = (props) => {
  const { id: postId } = props?.match?.params;

  const history = useHistory();
  const [, setState] = useState({});
  // const [fileImageList, setFileImageList] = useState([]);
  const formRef = useRef(null);

  const [selectedImage] = useState(null);

  useEffect(() => {
    if (postId) {
      fetchData(postId);
    }
    // eslint-disable-next-line
  }, [postId]);

  const fetchData = async (id) => {
    props.viewJob(id).then((res) => {
      const { data, status, message } = res?.data;
      if (!status) {
        notifyError(message);
        history.push("/admin/jobs");
        return;
      }

      const formData = Object.assign({}, data);
      const { job_image_url } = formData;

      formRef.current.setFieldsValue({
        ...formData,
      });

      if (job_image_url) {
        // const imageUrl = getFileUrl(job_image_url);
        // setFileImageList([
        //   {
        //     uid: "-1",
        //     status: "done",
        //     url: imageUrl,
        //     name: "Image",
        //   },
        // ]);
      }
    });
  };

  // const imageUpload = (options) => {
  //   if (!options) {
  //     setSelectedImage(null);
  //     return;
  //   }
  //   if (options.file) {
  //     setSelectedImage(options.file);
  //   }

  //   options.onSuccess();
  // };

  const onFormSubmit = (postData) => {
    const formData = new FormData();

    if (selectedImage) formData.append("post_image", selectedImage);
    Object.keys(postData).forEach((key) => {
      var value = postData?.[key] || "";
      formData.append(key, value);
    });

    const promise = postId
      ? props.editJob(postId, formData)
      : props.saveJob(formData);

    promise
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          props.history.push("/admin/jobs");
        } else {
          notifyError(message || "Error");
        }
      })
      .catch(() => {
        notifyError("Something went wrong");
      });
  };

  return (
    <div className="question-form">
      <Row>
        <Col flex={14}>
          <Form
            ref={formRef}
            layout="vertical"
            onValuesChange={(changedValues, allValues) => {
              setState(allValues);
            }}
            onFinish={onFormSubmit}
          >
            <Card>
              <h4>{postId ? "Edit" : "Add"} job</h4>
              <hr />
              {/* <Row gutter={[30, 20]}>
                <Col span={8}>
                  {fileImageList?.length > 0 && (
                    <Upload
                      customRequest={imageUpload}
                      onRemove={() => imageUpload(null)}
                      listType="picture"
                      maxCount={1}
                      defaultFileList={fileImageList}
                    >
                      <Button icon={<UploadOutlined />}>Upload Image</Button>
                    </Upload>
                  )}
                  {fileImageList?.length === 0 && (
                    <Upload
                      customRequest={imageUpload}
                      onRemove={() => imageUpload(null)}
                      listType="picture"
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>Upload Image</Button>
                    </Upload>
                  )}
                </Col>
              </Row> */}

              <Form.Item
                name="job_title"
                label="Job Title"
                rules={[RequiredRule("Please enter job title")]}
              >
                <Input placeholder="Enter Job title" />
              </Form.Item>

              <Form.Item
                name="job_description"
                label="Job description"
                rules={[RequiredRule("Please enter job description")]}
              >
                <Input.TextArea placeholder="Enter Job description" rows={4} />
              </Form.Item>

              <Form.Item name="job_apply_url" label="Job Apply URL">
                <Input placeholder="Enter Job apply url" />
              </Form.Item>

              <Form.Item name="job_description_url" label="Job Description URL">
                <Input placeholder="Enter Job description url" />
              </Form.Item>

              <Button htmlType="submit" type="primary">
                Save Job
              </Button>
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(ManageJobPage));
