import config from "../config";

const onApiError = (e) => {
  throw e;
};

const onApiSuccess = (res) => res;

export const tokenStorage = (function () {
  const COOKIE_NAME = "kdvs_admin_token";

  // Note: use local storage to store token whenever possible
  // if not found, downgrade to local variable, which will require users
  // to sign in after refreshing pages
  if (typeof localStorage !== "undefined") {
    return {
      get: () => {
        return localStorage.getItem(COOKIE_NAME);
      },
      set: (token) => {
        return localStorage.setItem(COOKIE_NAME, token);
      },
      remove: () => {
        return localStorage.removeItem(COOKIE_NAME);
      },
    };
  }

  let token = null;

  return {
    get: () => {
      return token;
    },
    set: (tk) => {
      token = tk;
    },
    remove: () => {
      token = null;
    },
  };
})();

const invokeApi = ({
  path,
  method = "GET",
  headers = {},
  authorization = false,
  body,
  params,
}) => {
  const apiUrl = new URL(config.API_URL + path);
  if (params) apiUrl.search = new URLSearchParams(params).toString();

  headers["x-api-key"] = config.API_KEY;

  if (authorization) {
    const token = tokenStorage.get();
    if (token && token.length && token !== "null") {
      headers.Authorization = `Bearer ${token}`;
    }
  }
  if (!(body instanceof FormData)) body = body && JSON.stringify(body);
  return fetch(apiUrl, {
    method,
    body,
    headers,
  }).then((results) => {
    if (results.status === 401 || results.status === 403) {
      logout();
      throw new Error("Unauthorized");
    }
    if (results.status !== 200) {
      return results.json().then((data) => {
        return Promise.reject(data);
      });
    }

    return results
      .json()
      .then((json) => {
        return Promise.resolve(json);
      })
      .catch((err) => Promise.reject(err));
  });
};

export const logout = () => {
  tokenStorage.remove();
  // history.push("/login");
};

const invokeGetAPI = async (path, params = {}) => {
  return await invokeApi({
    path,
    method: "GET",
    headers: { "Content-Type": "application/json" },
    authorization: true,
    params,
  });
};

const invokeProtectedGetAPI = async (path, params = {}) => {
  return await invokeApi({
    path,
    method: "GET",
    headers: { "Content-Type": "application/json" },
    authorization: true,
    params,
  });
};

const invokePostAPI = async (path, data) => {
  return await invokeApi({
    path,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  });
};
const invokePostFileAPI = async (path, data) => {
  return await invokeApi({
    path,
    method: "POST",
    authorization: true,
    body: data,
  });
};
const invokeDeleteAPI = async (path, data) => {
  return await invokeApi({
    path,
    method: "DELETE",
    authorization: true,
  });
};

const processToken = (result) => {
  if (result.status && result.token) {
    tokenStorage.set(result.token);
  }
  return result;
};

export const login = (data) =>
  invokeApi({
    method: "POST",
    path: "/admin/login",
    headers: { "Content-Type": "application/json" },
    body: data,
  })
    .then((res) => {
      return processToken(res);
    })
    .then(onApiSuccess, onApiError);

export const getUsers = () =>
  invokeProtectedGetAPI("/admin/users").then(onApiSuccess, onApiError);

export const getDistrict = () =>
  invokeGetAPI("/v1/districts").then(onApiSuccess, onApiError);

export const getTaluka = () =>
  invokeGetAPI("/v1/talukas").then(onApiSuccess, onApiError);

export const getCity = () =>
  invokeGetAPI("/v1/cities").then(onApiSuccess, onApiError);

export const getWard = () =>
  invokeGetAPI("/v1/wards").then(onApiSuccess, onApiError);

export const getSociety = () =>
  invokeGetAPI("/v1/societies").then(onApiSuccess, onApiError);

export const getAdminProfile = () =>
  invokeProtectedGetAPI("/admin/check").then(onApiSuccess, onApiError);

export const getDashboardStatistics = () =>
  invokeProtectedGetAPI("/admin/dashboard").then(onApiSuccess, onApiError);

/**************************** City / Ward ****************************/
export const postNewCity = (data) =>
  invokePostAPI(`/admin/save-city`, data).then(onApiSuccess, onApiError);

export const postNewWard = (data) =>
  invokePostAPI(`/admin/save-ward`, data).then(onApiSuccess, onApiError);

export const postNewSociety = (data) =>
  invokePostAPI(`/admin/save-society`, data).then(onApiSuccess, onApiError);

export const deleteCity = (id) =>
  invokeDeleteAPI(`/admin/city/${id}`).then(onApiSuccess, onApiError);

export const deleteWard = (id) =>
  invokeDeleteAPI(`/admin/ward/${id}`).then(onApiSuccess, onApiError);

export const deleteSociety = (id) =>
  invokeDeleteAPI(`/admin/society/${id}`).then(onApiSuccess, onApiError);

/********************************************************************/

/**************************** Core Members ****************************/
export const getCoreMembers = (data) =>
  invokePostAPI(`/admin/core-members/list`, data).then(
    onApiSuccess,
    onApiError
  );

export const getCoreMember = (id) =>
  invokeProtectedGetAPI(`/admin/core-members/${id}/view`).then(
    onApiSuccess,
    onApiError
  );

export const postCoreMember = (data) =>
  invokePostFileAPI(`/admin/core-members`, data).then(onApiSuccess, onApiError);

export const updateCoreMember = (id, data) =>
  invokePostFileAPI(`/admin/core-members/${id}/update`, data).then(
    onApiSuccess,
    onApiError
  );

export const updateCoreMemberStatus = (id, data) =>
  invokePostAPI(`/admin/core-members/${id}/update-status`, data).then(
    onApiSuccess,
    onApiError
  );

export const resetCoreMemberPassword = (id) =>
  invokePostAPI(`/admin/core-members/${id}/reset-password`, {}).then(
    onApiSuccess,
    onApiError
  );

/**********************************************************************************/

/**************************** KDVS Members ****************************/
export const getVolunteerMembers = (data) =>
  invokePostAPI(`/admin/kdvs-members/list`, data).then(
    onApiSuccess,
    onApiError
  );

export const deleteVolunteerMember = (id) =>
  invokeDeleteAPI(`/admin/kdvs-member/${id}`).then(onApiSuccess, onApiError);

/**********************************************************************************/

/**************************** Upcoming Birthday  ****************************/
export const getUpcomingBirthday = (data) =>
  invokeProtectedGetAPI(`/admin/upcoming-birthday`, data).then(
    onApiSuccess,
    onApiError
  );
/**********************************************************************************/

/**************************** Social Media Post ****************************/
export const getSocialMediaPosts = (data) =>
  invokePostAPI(`/admin/social-media-posts`, data).then(
    onApiSuccess,
    onApiError
  );

export const getSocialMediaPost = (id) =>
  invokeProtectedGetAPI(`/admin/social-media-post/${id}`).then(
    onApiSuccess,
    onApiError
  );

export const postSocialMediaPost = (data) =>
  invokePostFileAPI(`/admin/social-media-post`, data).then(
    onApiSuccess,
    onApiError
  );

export const updateSocialMediaPost = (id, data) =>
  invokePostFileAPI(`/admin/social-media-post/${id}`, data).then(
    onApiSuccess,
    onApiError
  );

export const deleteSocialMediaPost = (id) =>
  invokeDeleteAPI(`/admin/social-media-post/${id}`).then(
    onApiSuccess,
    onApiError
  );
/**********************************************************************************/

/**************************** Events Post ****************************/
export const getEvents = (data) =>
  invokePostAPI(`/admin/events`, data).then(onApiSuccess, onApiError);

export const getEvent = (id) =>
  invokeProtectedGetAPI(`/admin/event/${id}`).then(onApiSuccess, onApiError);

export const postEvent = (data) =>
  invokePostFileAPI(`/admin/event`, data).then(onApiSuccess, onApiError);

export const updateEvent = (id, data) =>
  invokePostFileAPI(`/admin/event/${id}`, data).then(onApiSuccess, onApiError);

export const deleteEvent = (id) =>
  invokeDeleteAPI(`/admin/event/${id}`).then(onApiSuccess, onApiError);
/**********************************************************************************/

/**************************** Jobs Post ****************************/
export const getJobs = (data) =>
  invokePostAPI(`/admin/jobs`, data).then(onApiSuccess, onApiError);

export const getJob = (id) =>
  invokeProtectedGetAPI(`/admin/job/${id}`).then(onApiSuccess, onApiError);

export const postJob = (data) =>
  invokePostFileAPI(`/admin/job`, data).then(onApiSuccess, onApiError);

export const updateJob = (id, data) =>
  invokePostFileAPI(`/admin/job/${id}`, data).then(onApiSuccess, onApiError);

export const deleteJob = (id) =>
  invokeDeleteAPI(`/admin/job/${id}`).then(onApiSuccess, onApiError);
/**********************************************************************************/

/**************************** Academic Batch Post ****************************/
export const getAcademicBatchs = (data) =>
  invokePostAPI(`/admin/academic-batchs`, data).then(onApiSuccess, onApiError);

export const getAcademicBatch = (id) =>
  invokeProtectedGetAPI(`/admin/academic-batch/${id}`).then(
    onApiSuccess,
    onApiError
  );

export const postAcademicBatch = (data) =>
  invokePostAPI(`/admin/academic-batch`, data).then(onApiSuccess, onApiError);

export const updateAcademicBatch = (id, data) =>
  invokePostAPI(`/admin/academic-batch/${id}`, data).then(
    onApiSuccess,
    onApiError
  );

export const deleteAcademicBatch = (id) =>
  invokeDeleteAPI(`/admin/academic-batch/${id}`).then(onApiSuccess, onApiError);
/**********************************************************************************/
