import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { withRouter } from "react-router-dom";
import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import {
  RequiredRule,
  filterTalukas,
  filterOption,
  filterCities,
  filterWards,
  filterSocieties,
} from "../../common/helper";
import { notifyError, notifySuccess } from "../../common/notification";
import { Popconfirm } from "antd";

const DataMasterPage = (props) => {
  const { districts, talukas, cities, wards, societies } = props;

  const cityForm = useRef(null);
  const wardForm = useRef(null);
  const societyForm = useRef(null);

  const [actionType, setActionType] = useState(null);
  const [districtId, setDistrictId] = useState(null);
  const [talukaId, setTalukaId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [wardId, setWardId] = useState(null);

  const [filteredTalukas, setFilteredTalukas] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredWards, setFilteredWards] = useState([]);
  const [filteredSocieties, setFilteredSocieties] = useState([]);

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    props.listDistrict();
    props.listTaluka();
    props.listCity();
    props.listWard();
    props.listSociety();
  };

  useEffect(() => {
    if (districtId) {
      setFilteredTalukas(filterTalukas(districtId, talukas));
    }
    // eslint-disable-next-line
  }, [districtId, talukas]);

  useEffect(() => {
    if (talukaId) {
      setFilteredCities(filterCities(talukaId, cities));
    }
    // eslint-disable-next-line
  }, [talukaId, cities]);

  useEffect(() => {
    if (cityId) {
      setFilteredWards(filterWards(cityId, wards));
    }
    // eslint-disable-next-line
  }, [cityId, wards]);

  useEffect(() => {
    if (wardId) {
      setFilteredSocieties(filterSocieties(wardId, societies));
    }
    // eslint-disable-next-line
  }, [wardId, societies]);

  const handleCityDelete = async (id) => {
    try {
      const res = await props.deleteCity(id);
      const { status, message } = res.data;
      if (status) {
        notifySuccess(message);
        fetchData();
      } else {
        notifyError(message || "Error");
      }
    } catch (err) {
      notifyError("Something went wrong");
    }
  };

  const handleWardDelete = async (id) => {
    try {
      const res = await props.deleteWard(id);
      const { status, message } = res.data;
      if (status) {
        notifySuccess(message);
        fetchData();
      } else {
        notifyError(message || "Error");
      }
    } catch (err) {
      notifyError("Something went wrong");
    }
  };

  const handleSocietyDelete = async (id) => {
    try {
      const res = await props.deleteSociety(id);
      const { status, message } = res.data;
      if (status) {
        notifySuccess(message);
        fetchData();
      } else {
        notifyError(message || "Error");
      }
    } catch (err) {
      notifyError("Something went wrong");
    }
  };

  const onCityFormSubmit = (postData) => {
    props
      .saveNewCity(postData)
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          notifySuccess(message);
          cityForm?.current?.resetFields();
          fetchData();
        } else {
          notifyError(message || "Error");
        }
      })
      .catch(() => {
        notifyError("Something went wrong");
      });
  };

  const onWardFormSubmit = (postData) => {
    props
      .saveNewWard(postData)
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          notifySuccess(message);
          wardForm?.current?.resetFields();
          fetchData();
        } else {
          notifyError(message || "Error");
        }
      })
      .catch(() => {
        notifyError("Something went wrong");
      });
  };

  const onSocietyFormSubmit = async (postData) => {
    try {
      const res = await props.saveNewSociety(postData);
      const { status, message } = res.data;
      if (status) {
        notifySuccess(message);
        societyForm?.current?.resetFields();
        fetchData();
      } else {
        notifyError(message || "Error");
      }
    } catch (err) {
      notifyError("Something went wrong");
    }
  };

  return (
    <>
      <Row gutter={[10]}>
        <Col>
          <Button type="primary" onClick={() => setActionType("add_city")}>
            + Add new city/village
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={() => setActionType("add_ward")}>
            + Add new ward
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={() => setActionType("add_society")}>
            + Add new society
          </Button>
        </Col>
      </Row>

      <Row gutter={[10]} style={{ marginTop: 20 }}>
        {actionType === "add_city" && (
          <Col span={12}>
            <Card>
              <Form
                ref={cityForm}
                layout="vertical"
                onFinish={onCityFormSubmit}
              >
                <h4>Add city/village</h4>
                <hr />

                <Form.Item
                  name="district_id"
                  label="District"
                  rules={[RequiredRule("Please select District")]}
                >
                  <Select
                    placeholder="Select District"
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => setDistrictId(value)}
                  >
                    {districts?.map((item, index) => (
                      <Select.Option key={index} value={item.district_id}>
                        {item.district_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="taluka_id"
                  label="Taluka"
                  rules={[RequiredRule("Please select Taluka")]}
                >
                  <Select
                    placeholder="Select Taluka"
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => setTalukaId(value)}
                  >
                    {filteredTalukas?.map((item, index) => (
                      <Select.Option key={index} value={item.taluka_id}>
                        {item.taluka_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="city_name"
                  label="City/Village"
                  rules={[RequiredRule("Please enter city/village name")]}
                >
                  <Input placeholder="Enter city/village name" />
                </Form.Item>

                <Button htmlType="submit" type="primary">
                  Save
                </Button>

                {filteredCities?.length > 0 && (
                  <div className="list-item">
                    <p>Already Added Cities:</p>
                    <table className="table">
                      {filteredCities?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.city_name}</td>
                          <td>
                            <Popconfirm
                              title="Sure to delete?"
                              onConfirm={() => {
                                handleCityDelete(item.city_id);
                              }}
                            >
                              <Button type="primary" danger size="small">
                                Delete
                              </Button>
                            </Popconfirm>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                )}
              </Form>
            </Card>
          </Col>
        )}

        {actionType === "add_ward" && (
          <Col span={12}>
            <Card>
              <Form
                ref={wardForm}
                layout="vertical"
                onFinish={onWardFormSubmit}
              >
                <h4>Add ward</h4>
                <hr />

                <Form.Item
                  name="district_id"
                  label="District"
                  rules={[RequiredRule("Please select District")]}
                >
                  <Select
                    placeholder="Select District"
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => setDistrictId(value)}
                  >
                    {districts?.map((item, index) => (
                      <Select.Option key={index} value={item.district_id}>
                        {item.district_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="taluka_id"
                  label="Taluka"
                  rules={[RequiredRule("Please select Taluka")]}
                >
                  <Select
                    placeholder="Select Taluka"
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => setTalukaId(value)}
                  >
                    {filteredTalukas?.map((item, index) => (
                      <Select.Option key={index} value={item.taluka_id}>
                        {item.taluka_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="city_id"
                  label="City"
                  rules={[RequiredRule("Please select City")]}
                >
                  <Select
                    placeholder="Select City"
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => setCityId(value)}
                  >
                    {filteredCities?.map((item, index) => (
                      <Select.Option key={index} value={item.city_id}>
                        {item.city_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="ward_name"
                  label="Ward name"
                  rules={[RequiredRule("Please enter ward name")]}
                >
                  <Input placeholder="Enter ward name" />
                </Form.Item>

                <Button htmlType="submit" type="primary">
                  Save
                </Button>

                {filteredWards?.length > 0 && (
                  <div className="list-item">
                    <p>Already Added Wards:</p>

                    <table className="table">
                      {filteredWards?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.ward_name}</td>
                          <td>
                            <Popconfirm
                              title="Sure to delete?"
                              onConfirm={() => {
                                handleWardDelete(item.ward_id);
                              }}
                            >
                              <Button type="primary" danger size="small">
                                Delete
                              </Button>
                            </Popconfirm>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                )}
              </Form>
            </Card>
          </Col>
        )}

        {actionType === "add_society" && (
          <Col span={12}>
            <Card>
              <Form
                ref={societyForm}
                layout="vertical"
                onFinish={onSocietyFormSubmit}
              >
                <h4>Add society</h4>
                <hr />

                <Form.Item
                  name="district_id"
                  label="District"
                  rules={[RequiredRule("Please select District")]}
                >
                  <Select
                    placeholder="Select District"
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => setDistrictId(value)}
                  >
                    {districts?.map((item, index) => (
                      <Select.Option key={index} value={item.district_id}>
                        {item.district_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="taluka_id"
                  label="Taluka"
                  rules={[RequiredRule("Please select Taluka")]}
                >
                  <Select
                    placeholder="Select Taluka"
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => setTalukaId(value)}
                  >
                    {filteredTalukas?.map((item, index) => (
                      <Select.Option key={index} value={item.taluka_id}>
                        {item.taluka_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="city_id"
                  label="City"
                  rules={[RequiredRule("Please select City")]}
                >
                  <Select
                    placeholder="Select City"
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => setCityId(value)}
                  >
                    {filteredCities?.map((item, index) => (
                      <Select.Option key={index} value={item.city_id}>
                        {item.city_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="ward_id"
                  label="Ward"
                  rules={[RequiredRule("Please select ward")]}
                >
                  <Select
                    placeholder="Select ward"
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => setWardId(value)}
                  >
                    {filteredWards?.map((item, index) => (
                      <Select.Option key={index} value={item.ward_id}>
                        {item.ward_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="society_name"
                  label="Society name"
                  rules={[RequiredRule("Please enter society name")]}
                >
                  <Input placeholder="Enter society name" />
                </Form.Item>

                <Button htmlType="submit" type="primary">
                  Save
                </Button>

                {filteredSocieties?.length > 0 && (
                  <div className="list-item">
                    <p>Already Added Societies:</p>

                    <table className="table">
                      {filteredSocieties?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.society_name}</td>
                          <td>
                            <Popconfirm
                              title="Sure to delete?"
                              onConfirm={() => {
                                handleSocietyDelete(item.society_id);
                              }}
                            >
                              <Button type="primary" danger size="small">
                                Delete
                              </Button>
                            </Popconfirm>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                )}
              </Form>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default connect(
  (state) => ({
    districts: state.districts,
    talukas: state.talukas,
    cities: state.cities,
    wards: state.wards,
    societies: state.societies,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(DataMasterPage));
