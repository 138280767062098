import config from "../config";

export const RequiredRule = (message) => ({
  required: true,
  message: message,
});

export const MobileNumberRule = (message) => ({
  pattern: new RegExp("^[0-9]{10}$"),
  message: message,
});

export const EmailRule = (message) => ({
  type: "email",
  message: message,
});

export const filterTalukas = (districtId, talukaList) => {
  return talukaList?.filter((item) => item._district_id === districtId) || [];
};

export const filterCities = (talukaId, cityList) => {
  return cityList?.filter((item) => item._taluka_id === talukaId) || [];
};

export const filterWards = (cityId, wardList) => {
  return wardList?.filter((item) => item._city_id === cityId) || [];
};

export const filterSocieties = (wardId, societyList) => {
  return societyList?.filter((item) => item._ward_id === wardId) || [];
};

export const filterOption = (input, option) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const filterParentDetails = (
  type,
  id,
  districts = null,
  talukas = null,
  cities = null,
  wards = null,
  societies = null
) => {
  if (!id) return null;
  const parents = [];
  const societyItem = societies?.find(
    (item) => type === "society" && item.society_id === id
  );

  const wardItem = wards?.find(
    (item) =>
      (type === "ward" && item.ward_id === id) ||
      (societyItem && item.ward_id === societyItem?._ward_id)
  );
  const cityItem = cities?.find(
    (item) =>
      (type === "city" && item.city_id === id) ||
      (wardItem && item.city_id === wardItem?._city_id)
  );
  const talukaItem = talukas?.find(
    (item) =>
      (type === "taluka" && item.taluka_id === id) ||
      (cityItem && item.taluka_id === cityItem?._taluka_id)
  );
  const districtItem = districts?.find(
    (item) => item.district_id === talukaItem?._district_id
  );

  if (districtItem)
    parents.push({ type: "District", label: districtItem.district_name });

  if (talukaItem)
    parents.push({ type: "Taluka", label: talukaItem.taluka_name });
  if (cityItem) parents.push({ type: "City", label: cityItem.city_name });
  if (wardItem) parents.push({ type: "Ward", label: wardItem.ward_name });
  if (societyItem)
    parents.push({ type: "Society", label: societyItem.society_name });

  // wrap in span
  return (
    <>
      {parents.map((item, index) => (
        <span key={index}>
          <strong>{item?.type}</strong> : {item?.label}
        </span>
      ))}
    </>
  );
};

export const badgeGenerator = (status) => {
  const title =
    status?.replace(/_/g, " ").charAt(0).toUpperCase() +
    status?.replace(/_/g, " ").slice(1);

  return <span className={`badge ${status}`}>{title}</span>;
};

export const getFileUrl = (file) => {
  if (!file) return null;
  if (file.startsWith("http")) {
    return file;
  }
  return `${config.API_URL}/public/uploads/${file}`;
};
