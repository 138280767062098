import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./actions";
import * as API from "./services/api";
import { Route, Switch, withRouter } from "react-router-dom";
import { protectedRoutes, publicRoutes } from "./routes";
import Loading from "./components/loading";
import BaseLayout from "./components/layout/index";
import "antd/dist/reset.css";
import "./App.less";

function App(props) {
  const { isLoggedIn, history } = props;

  useEffect(() => {
    if (history) {
      props.setRoute(history.location.pathname);
      props.history.listen((location, action) => {
        window.scrollTo(0, 0);
      });
    }
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    const m = props?.route?.match(/^\/([^/]+)/);
    document.body.className = m && m[1];
    if (props.route !== "/login") {
      checkAdminInfo();
    }
    // eslint-disable-next-line
  }, [props.route]);

  const checkAdminInfo = () => {
    const token = API.tokenStorage.get();
    if (token && token.length && token !== "null") {
      API.getAdminProfile()
        .then((result) => {
          // Login Success
          if (result.status) {
            props.setAdminInfo(result.data);
          } else {
            props.logout();
          }
        })
        .catch(() => {
          props.logout();
        });
    } else {
      props.history.push("/login");
    }
  };

  return (
    <div className="app">
      <section className="content">
        {isLoggedIn ? (
          <BaseLayout>
            <Switch>
              {protectedRoutes.map((route, i) => (
                <Route {...route} key={i} />
              ))}
            </Switch>
          </BaseLayout>
        ) : (
          <Switch>
            {publicRoutes.map((route, i) => (
              <Route {...route} key={i} />
            ))}
          </Switch>
        )}
      </section>

      <Loading />
    </div>
  );
}

export default connect(
  (state) => ({
    route: state.route,
    isLoggedIn: state.isLoggedIn,
    isLoading: state.isLoading,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(App));
