import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { withRouter } from "react-router-dom";
import { Button, Col, Row, Table, Image } from "antd";
import { getFileUrl } from "../../common/helper";
import moment from "moment-timezone";
// import "./index.less";

const UpcomingBirthdayPage = (props) => {
  const { upcoming_birthday } = props;

  const [type, setType] = useState("today");

  useEffect(() => {
    props.listUpcomingBirthday();
    // eslint-disable-next-line
  }, []);

  const changeType = () => {
    if (type === "today") {
      setType("next3days");
    } else {
      setType("today");
    }
  };

  useEffect(() => {
    const params = {
      type: type,
    };

    props.listUpcomingBirthday(params);
    // eslint-disable-next-line
  }, [type]);

  const results = upcoming_birthday?.map((item, index) => {
    const { user_id } = item;
    return {
      ...item,
      key: user_id,
      sr_no: index + 1,
    };
  });

  const columns = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Birth Date",
      dataIndex: "birth_date",
      key: "birth_date",
      render: (text, record) => (
        <>{moment(record?.date_of_birth).format("DD-MM-YYYY")}</>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <>
          {record?.surname} {record?.first_name} {record?.last_name}
        </>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Whatsapp Number",
      dataIndex: "whatsapp_no",
      key: "whatsapp_no",
      render: (text, record) => (
        <>
          <a
            href={`https://wa.me/+91${record?.whatsapp_no}`}
            target="_blank"
            rel="noreferrer"
          >
            {record?.whatsapp_no}
          </a>
        </>
      ),
    },
    {
      title: "Image",
      dataIndex: "user_image",
      key: "user_image",
      render: (text, record) => (
        <>
          <Image src={getFileUrl(record?.user_image)} alt="post" width={100} />
        </>
      ),
    },
  ];

  return (
    <>
      <Row gutter={[10, 20]} className="filter-row">
        <Col>
          <h4>Upcoming Birthday</h4>
        </Col>
      </Row>

      <Row gutter={[10, 20]} className="filter-row">
        <Col>
          <Button onClick={changeType}>
            {type === "today" ? "Today" : "Next 3 Days"}
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={columns}
        pagination={{
          position: "bottomRight",
          pageSize: 10,
          hideOnSinglePage: true,
        }}
      />
    </>
  );
};

export default connect(
  (state) => ({
    upcoming_birthday: state.upcoming_birthday,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(UpcomingBirthdayPage));
