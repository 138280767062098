import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { useHistory, withRouter } from "react-router-dom";
import { Button, Card, Col, Form, Input, Row, DatePicker, Select } from "antd";
import { RequiredRule } from "../../common/helper";
import { notifyError } from "../../common/notification";
import dayjs from "dayjs";

const BATCH_TYPE = [
  { value: "class_3_general", lable: "Class 3 - General" },
  { value: "psi", lable: "PSI/ASI/LRD" },
  { value: "bin_sachivalay", lable: "Bin Sachivalay" },
];

const BATCH_STATUS = [
  { value: "upcoming", lable: "Upcoming" },
  { value: "running", lable: "Running" },
  { value: "completed", lable: "Completed" },
];

const ManageAcademicBatchPage = (props) => {
  const { id: postId } = props?.match?.params;

  const history = useHistory();
  const [, setState] = useState({});
  const formRef = useRef(null);

  useEffect(() => {
    if (postId) {
      fetchData(postId);
    }
    // eslint-disable-next-line
  }, [postId]);

  const fetchData = async (id) => {
    props.viewAcademicBatch(id).then((res) => {
      const { data, status, message } = res?.data;
      if (!status) {
        notifyError(message);
        history.push("/admin/academic-batchs");
        return;
      }

      const formData = Object.assign({}, data);
      const { batch_start_date, batch_end_date } = formData;
      delete formData.batch_start_date;
      delete formData.batch_end_date;

      formRef.current.setFieldsValue({
        ...formData,
      });

      if (batch_start_date) {
        const selectedDate = dayjs(batch_start_date, "YYYY-MM-DD");
        formRef.current.setFieldsValue({
          batch_start_date: selectedDate,
        });
      }
      if (batch_end_date) {
        const selectedDate = dayjs(batch_end_date, "YYYY-MM-DD");
        formRef.current.setFieldsValue({
          batch_end_date: selectedDate,
        });
      }
    });
  };

  const onFormSubmit = (postData) => {
    const formData = {};

    Object.keys(postData).forEach((key) => {
      var value = postData?.[key] || "";
      if (value && (key === "batch_start_date" || key === "batch_end_date")) {
        value = dayjs(value).format("YYYY-MM-DD");
      }
      formData[key] = value;
    });

    const promise = postId
      ? props.editAcademicBatch(postId, formData)
      : props.saveAcademicBatch(formData);

    promise
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          props.history.push("/admin/academic-batchs");
        } else {
          notifyError(message || "Error");
        }
      })
      .catch(() => {
        notifyError("Something went wrong");
      });
  };

  return (
    <div className="question-form">
      <Row>
        <Col flex={14}>
          <Form
            ref={formRef}
            layout="vertical"
            onValuesChange={(changedValues, allValues) => {
              setState(allValues);
            }}
            onFinish={onFormSubmit}
          >
            <Card>
              <h4>{postId ? "Edit" : "Add"} academic batch</h4>
              <hr />
              <Form.Item
                name="batch_name"
                label="Batch name"
                rules={[RequiredRule("Please enter batch name")]}
              >
                <Input placeholder="Enter batch name" />
              </Form.Item>

              <Row gutter={[30, 20]}>
                <Col span={6}>
                  <Form.Item
                    name="batch_type"
                    label="Batch type"
                    rules={[RequiredRule("Please select batch type")]}
                  >
                    <Select placeholder="Select batch type">
                      {BATCH_TYPE.map((item, index) => (
                        <Select.Option key={item.index} value={item.value}>
                          {item.lable}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[30, 20]}>
                <Col span={6}>
                  <Form.Item
                    name="batch_start_date"
                    label="Batch start date"
                    rules={[RequiredRule("Please select batch start date")]}
                  >
                    <DatePicker
                      placeholder="Select start date"
                      format={"DD MMM YYYY"}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="batch_end_date" label="Batch end date">
                    <DatePicker
                      placeholder="Select end date"
                      format={"DD MMM YYYY"}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="batch_description"
                label="Batch description"
                rules={[RequiredRule("Please enter batch description")]}
              >
                <Input.TextArea
                  placeholder="Enter batch description"
                  rows={4}
                />
              </Form.Item>

              <Row gutter={[30, 20]}>
                <Col span={6}>
                  <Form.Item
                    name="status"
                    label="Batch status"
                    rules={[RequiredRule("Please select batch status")]}
                  >
                    <Select placeholder="Select batch status">
                      {BATCH_STATUS.map((item, index) => (
                        <Select.Option key={item.index} value={item.value}>
                          {item.lable}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Button htmlType="submit" type="primary">
                Save Batch
              </Button>
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(ManageAcademicBatchPage));
