import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { useHistory, withRouter } from "react-router-dom";
import { Button, Card, Col, Form, Input, Row, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RequiredRule, getFileUrl } from "../../common/helper";
import { notifyError } from "../../common/notification";

const ManageSocialMediaPostPage = (props) => {
  const { id: postId } = props?.match?.params;

  const history = useHistory();
  const [, setState] = useState({});
  const [fileImageList, setFileImageList] = useState([]);
  const formRef = useRef(null);

  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (postId) {
      fetchData(postId);
    }
    // eslint-disable-next-line
  }, [postId]);

  const fetchData = async (id) => {
    props.viewSocialMediaPost(id).then((res) => {
      const { data, status, message } = res?.data;
      if (!status) {
        notifyError(message);
        history.push("/admin/social-media-posts");
        return;
      }

      const formData = Object.assign({}, data);
      const { post_image_url } = formData;

      formRef.current.setFieldsValue({
        ...formData,
      });

      if (post_image_url) {
        const imageUrl = getFileUrl(post_image_url);
        setFileImageList([
          {
            uid: "-1",
            status: "done",
            url: imageUrl,
            name: "Image",
          },
        ]);
      }
    });
  };

  const imageUpload = (options) => {
    if (!options) {
      setSelectedImage(null);
      return;
    }
    if (options.file) {
      setSelectedImage(options.file);
    }

    options.onSuccess();
  };

  const onFormSubmit = (postData) => {
    if (!selectedImage && !postId) {
      notifyError("Please upload image");
      return;
    }

    const formData = new FormData();

    if (selectedImage) formData.append("post_image", selectedImage);
    Object.keys(postData).forEach((key) => {
      formData.append(key, postData[key]);
    });

    const promise = postId
      ? props.editSocialMediaPost(postId, formData)
      : props.saveSocialMediaPost(formData);

    promise
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          props.history.push("/admin/social-media-posts");
        } else {
          notifyError(message || "Error");
        }
      })
      .catch(() => {
        notifyError("Something went wrong");
      });
  };

  return (
    <div className="question-form">
      <Row>
        <Col flex={14}>
          <Form
            ref={formRef}
            layout="vertical"
            onValuesChange={(changedValues, allValues) => {
              setState(allValues);
            }}
            onFinish={onFormSubmit}
          >
            <Card>
              <h4>{postId ? "Edit" : "Add"} social media post</h4>
              <hr />
              <Row gutter={[30, 20]}>
                <Col span={8}>
                  {fileImageList?.length > 0 && (
                    <Upload
                      customRequest={imageUpload}
                      onRemove={() => imageUpload(null)}
                      listType="picture"
                      maxCount={1}
                      defaultFileList={fileImageList}
                    >
                      <Button icon={<UploadOutlined />}>Upload Image</Button>
                    </Upload>
                  )}
                  {fileImageList?.length === 0 && (
                    <Upload
                      customRequest={imageUpload}
                      onRemove={() => imageUpload(null)}
                      listType="picture"
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>Upload Image</Button>
                    </Upload>
                  )}
                </Col>
              </Row>

              <Row gutter={[30, 20]}>
                <Col flex={1}>
                  <Form.Item
                    name="post_title"
                    label="Post Title"
                    rules={[RequiredRule("Please enter post title")]}
                  >
                    <Input placeholder="Enter Post title" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="post_description"
                label="Post description"
                rules={[RequiredRule("Please enter post description")]}
              >
                <Input.TextArea placeholder="Enter post description" rows={4} />
              </Form.Item>

              <Button htmlType="submit" type="primary">
                Save Post
              </Button>
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(ManageSocialMediaPostPage));
