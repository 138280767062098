import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Space,
  Table,
  Popconfirm,
} from "antd";
import { CSVLink } from "react-csv";
import {
  filterTalukas,
  filterWards,
  filterOption,
  filterCities,
  filterSocieties,
} from "../../common/helper";
import {
  CheckSquareFilled,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment-timezone";
import { notifyError, notifySuccess } from "../../common/notification";
import "./index.less";

const PAGE_LIMIT = 20;

const KdvsMembersListPage = (props) => {
  const {
    kdvs_members,
    total_kdvs_members,
    districts,
    talukas,
    cities,
    wards,
    societies,
  } = props;

  const [searchText, setSearchText] = useState("");

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);

  const [districtId, setDistrictId] = useState(null);
  const [talukaId, setTalukaId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [wardId, setWardId] = useState(null);
  const [societyId, setSocietyId] = useState(null);

  const [filteredTalukas, setFilteredTalukas] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredWards, setFilteredWards] = useState([]);
  const [filteredSocieties, setFilteredSocieties] = useState([]);

  const datatable = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span>
          {record?.surname} {record?.first_name} {record?.last_name}
        </span>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile_no",
      key: "mobile_no",
    },
    {
      title: "BG",
      dataIndex: "blood_group",
      key: "blood_group",
      render: (text, record) => (
        <>
          {record?.blood_group}{" "}
          {!!record?.interest_in_blood_donation && <CheckSquareFilled />}
        </>
      ),
    },
    {
      title: "District",
      dataIndex: "district_name",
      key: "district_name",
    },
    {
      title: "Taluka",
      dataIndex: "taluka_name",
      key: "taluka_name",
    },
    {
      title: "City",
      dataIndex: "city_name",
      key: "city_name",
    },
    {
      title: "Ward",
      dataIndex: "ward_name",
      key: "ward_name",
    },
    {
      title: "Society",
      dataIndex: "society_name",
      key: "society_name",
    },
    {
      title: "Action",
      key: "action",
      render: (_text, record) => (
        <div className="btn-actions">
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => {
              handleDelete(record.member_id);
            }}
          >
            <Button type="primary" size="small" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    props.listDistrict();
    props.listTaluka();
    props.listCity();
    props.listWard();
    props.listSociety();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (districtId) {
      setFilteredTalukas(filterTalukas(districtId, talukas));
    }
    // eslint-disable-next-line
  }, [districtId]);

  useEffect(() => {
    if (talukaId) {
      setFilteredCities(filterCities(talukaId, cities));
    }
    // eslint-disable-next-line
  }, [talukaId]);

  useEffect(() => {
    if (cityId) {
      setFilteredWards(filterWards(cityId, wards));
    }
    // eslint-disable-next-line
  }, [cityId]);

  useEffect(() => {
    if (wardId) {
      setFilteredSocieties(filterSocieties(wardId, societies));
    }
    // eslint-disable-next-line
  }, [wardId]);

  const handleFilter = () => {
    fetchData();
  };

  const fetchData = () => {
    const params = {};
    if (districtId) params.district_id = districtId;
    if (talukaId) params.taluka_id = talukaId;
    if (cityId) params.city_id = cityId;
    if (wardId) params.ward_id = wardId;
    if (societyId) params.society_id = societyId;
    if (searchText) params.keyword = searchText;

    params.limit = pageLimit;
    params.page = pageNo;

    props.listVolunteerMember(params);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, pageLimit]);

  const handleDelete = async (id) => {
    const result = await props.removeVolunteerMember(id);
    const { status, message } = result?.data;
    if (status) {
      notifySuccess(message);
      fetchData();
      return;
    }

    notifyError(message);
  };

  let results = [];

  if (kdvs_members?.length > 0) {
    results = kdvs_members.map((row, index) => {
      const obj = {
        sr_no: index + 1,
        key: row.member_id,
        ...row,
      };
      delete obj["member_id"];
      delete obj["_user_id"];
      delete obj["user_image"];
      delete obj["district_id"];
      delete obj["taluka_id"];
      delete obj["city_id"];
      delete obj["ward_id"];
      delete obj["society_id"];
      delete obj["status"];
      delete obj["is_deleted"];
      return obj;
    });
  }

  const exportTimeStamp = moment().format("YYYY-MM-DD-HH:mm:ss");

  return (
    <>
      <Row gutter={[10, 20]} className="filter-row">
        <Col>
          <h4>Volunteer Members</h4>
        </Col>
        <Col>
          <Space>
            {results?.length > 0 && (
              <CSVLink
                data={results}
                filename={`kdvs-volunteer-member-${exportTimeStamp}.csv`}
              >
                <Button type="primary">
                  <DownloadOutlined />
                  Download CSV
                </Button>
              </CSVLink>
            )}
          </Space>
        </Col>
      </Row>

      <Row gutter={[10, 20]} className="filter-row">
        <Col>
          <Input
            defaultValue={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search"
          />
        </Col>
        <Col flex={1}>
          <div className="filter-menu">
            <Select
              placeholder="Select District"
              showSearch
              filterOption={filterOption}
              onChange={(value) => setDistrictId(value)}
            >
              {districts?.map((item, index) => (
                <Select.Option key={index} value={item.district_id}>
                  {item.district_name}
                </Select.Option>
              ))}
            </Select>

            <Select
              placeholder="Select Taluka"
              showSearch
              filterOption={filterOption}
              onChange={(value) => setTalukaId(value)}
            >
              {filteredTalukas?.map((item, index) => (
                <Select.Option key={index} value={item.taluka_id}>
                  {item.taluka_name}
                </Select.Option>
              ))}
            </Select>

            <Select
              placeholder="Select City"
              showSearch
              filterOption={filterOption}
              onChange={(value) => setCityId(value)}
            >
              {filteredCities?.map((item, index) => (
                <Select.Option key={index} value={item.city_id}>
                  {item.city_name}
                </Select.Option>
              ))}
            </Select>

            <Select
              placeholder="Select Ward"
              showSearch
              filterOption={filterOption}
              onChange={(value) => setWardId(value)}
            >
              {filteredWards?.map((item, index) => (
                <Select.Option key={index} value={item.ward_id}>
                  {item.ward_name}
                </Select.Option>
              ))}
            </Select>

            <Select
              placeholder="Select Society"
              showSearch
              filterOption={filterOption}
              onChange={(value) => setSocietyId(value)}
            >
              {filteredSocieties?.map((item, index) => (
                <Select.Option key={index} value={item.society_id}>
                  {item.society_name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col>
          <Button type="secondary" onClick={handleFilter}>
            Search
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={datatable}
        expandable={{
          expandedRowRender: (record) => {
            const {
              address,
              education_info,
              occupation_info,
              social_work_info,
              is_active_member,
              date_of_birth,
              im_surname,
              im_first_name,
              im_last_name,
            } = record || {};
            return (
              <div className="expandable-table">
                <p>
                  <strong>Created By:</strong>{" "}
                  {`${im_surname || ""} ${im_first_name || ""} ${
                    im_last_name || ""
                  }`}
                </p>
                <p>
                  <strong>Address:</strong> {address}
                </p>
                <p>
                  <strong>Education Info:</strong> {education_info}
                </p>
                <p>
                  <strong>Occupation Info:</strong> {occupation_info}
                </p>
                <p>
                  <strong>Social Work Info:</strong> {social_work_info}
                </p>
                <p>
                  <strong>Is Active Member:</strong>{" "}
                  {is_active_member ? "Yes" : "No"}
                </p>
                <p>
                  <strong>Date of Birth:</strong>{" "}
                  {moment(date_of_birth).format("DD MMM YYYY")}
                </p>
              </div>
            );
          },
        }}
        pagination={{
          position: "bottomRight",
          pageSize: pageLimit,
          total: total_kdvs_members,
          current: pageNo,
          showSizeChanger: true,
          onChange: (page, limit) => {
            setPageNo(page);
            setPageLimit(limit);
          },
        }}
      />
    </>
  );
};

export default connect(
  (state) => ({
    districts: state.districts,
    talukas: state.talukas,
    cities: state.cities,
    wards: state.wards,
    societies: state.societies,
    kdvs_members: state.kdvs_members,
    total_kdvs_members: state.total_kdvs_members,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(KdvsMembersListPage));
