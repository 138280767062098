import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { useHistory, withRouter } from "react-router-dom";
import { Button, Card, Col, Form, Input, Row, Upload, DatePicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RequiredRule, getFileUrl } from "../../common/helper";
import { notifyError } from "../../common/notification";
import moment from "moment-timezone";
import dayjs from "dayjs";

const ManageEventPage = (props) => {
  const { id: postId } = props?.match?.params;

  const history = useHistory();
  const [, setState] = useState({});
  const [fileImageList, setFileImageList] = useState([]);
  const formRef = useRef(null);

  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (postId) {
      fetchData(postId);
    }
    // eslint-disable-next-line
  }, [postId]);

  const fetchData = async (id) => {
    props.viewEvent(id).then((res) => {
      const { data, status, message } = res?.data;
      if (!status) {
        notifyError(message);
        history.push("/admin/events");
        return;
      }

      const formData = Object.assign({}, data);
      const { event_image_url, event_start_date } = formData;
      delete formData.event_start_date;

      formRef.current.setFieldsValue({
        ...formData,
      });

      if (event_start_date) {
        const selectedDate = dayjs(event_start_date, "YYYY-MM-DD HH:mm:ss");
        formRef.current.setFieldsValue({
          event_start_date: selectedDate,
        });
      }

      if (event_image_url) {
        const imageUrl = getFileUrl(event_image_url);
        setFileImageList([
          {
            uid: "-1",
            status: "done",
            url: imageUrl,
            name: "Image",
          },
        ]);
      }
    });
  };

  const imageUpload = (options) => {
    if (!options) {
      setSelectedImage(null);
      return;
    }
    if (options.file) {
      setSelectedImage(options.file);
    }

    options.onSuccess();
  };

  const onFormSubmit = (postData) => {
    if (!selectedImage && !postId) {
      notifyError("Please upload image");
      return;
    }

    const formData = new FormData();

    if (selectedImage) formData.append("post_image", selectedImage);
    Object.keys(postData).forEach((key) => {
      var value = postData?.[key] || "";
      if (key === "event_start_date") {
        value = dayjs(value).format("YYYY-MM-DD HH:mm:ss");
      }
      formData.append(key, value);
    });

    const promise = postId
      ? props.editEvent(postId, formData)
      : props.saveEvent(formData);

    promise
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          props.history.push("/admin/events");
        } else {
          notifyError(message || "Error");
        }
      })
      .catch(() => {
        notifyError("Something went wrong");
      });
  };

  return (
    <div className="question-form">
      <Row>
        <Col flex={14}>
          <Form
            ref={formRef}
            layout="vertical"
            onValuesChange={(changedValues, allValues) => {
              setState(allValues);
            }}
            onFinish={onFormSubmit}
          >
            <Card>
              <h4>{postId ? "Edit" : "Add"} event</h4>
              <hr />
              <Row gutter={[30, 20]}>
                <Col span={8}>
                  {fileImageList?.length > 0 && (
                    <Upload
                      customRequest={imageUpload}
                      onRemove={() => imageUpload(null)}
                      listType="picture"
                      maxCount={1}
                      defaultFileList={fileImageList}
                    >
                      <Button icon={<UploadOutlined />}>Upload Image</Button>
                    </Upload>
                  )}
                  {fileImageList?.length === 0 && (
                    <Upload
                      customRequest={imageUpload}
                      onRemove={() => imageUpload(null)}
                      listType="picture"
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>Upload Image</Button>
                    </Upload>
                  )}
                </Col>
              </Row>

              <Form.Item
                name="event_title"
                label="Event Title"
                rules={[RequiredRule("Please enter event title")]}
              >
                <Input placeholder="Enter Event title" />
              </Form.Item>

              <Row gutter={[30, 20]}>
                <Col span={8}>
                  <Form.Item
                    name="event_start_date"
                    label="Event Date"
                    rules={[RequiredRule("Please select event date")]}
                  >
                    <DatePicker
                      placeholder="Select Event Date"
                      format={"DD MMM YYYY, hh:mm a"}
                      disabledDate={(current) => current.isBefore(moment())}
                      showTime
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="event_description"
                label="Event description"
                rules={[RequiredRule("Please enter event description")]}
              >
                <Input.TextArea
                  placeholder="Enter event description"
                  rows={4}
                />
              </Form.Item>

              <Button htmlType="submit" type="primary">
                Save Event
              </Button>
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(ManageEventPage));
