import { Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";

const Dashboard = (props) => {
  const { dashboard_statistics } = props;

  useEffect(() => {
    props.listDashboardStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row gutter={[15, 20]}>
        <Col xs={24} md={6}>
          <Card title="Core committee members">
            <h4>{dashboard_statistics?.core_committee}</h4>
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card title="Convener">
            <h4>{dashboard_statistics?.convener}</h4>
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card title="Sub Convener">
            <h4>{dashboard_statistics?.sub_convener}</h4>
          </Card>
        </Col>
      </Row>
      <Row gutter={[15, 20]} style={{ marginTop: 20 }}>
        <Col xs={24} md={6}>
          <Card title="KDVS Active Members">
            <h4>{dashboard_statistics?.active_member}</h4>
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card title="KDVS Normal Members">
            <h4>{dashboard_statistics?.non_active_member}</h4>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default connect(
  (state) => ({
    admin: state.adminInfo,
    dashboard_statistics: state.dashboard_statistics,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Dashboard));
