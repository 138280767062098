import {
  GlobalOutlined,
  HomeOutlined,
  ReadOutlined,
  ScheduleOutlined,
  TeamOutlined,
  SmileOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu } from "antd";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import i18n from "../../i18n";
import moment from "moment-timezone";
import "./index.less";

const { Header, Content, Footer, Sider } = Layout;

const BaseLayout = ({ logout, children, history }) => {
  const handleMenuClick = (e) => {
    const item = menuItems.find((item) => item.key === e.key);
    const { link } = item || {};
    if (link) {
      history.push(link);
    }
  };

  const logoutHandler = () => {
    logout();
    history.push("/login");
  };

  const menuItems = [
    {
      key: "1",
      icon: <HomeOutlined />,
      label: "Dashboard",
      link: "/admin/dashboard",
    },
    {
      key: "2",
      icon: <TeamOutlined />,
      label: "Internal Members",
      link: "/admin/core-members",
    },
    {
      key: "3",
      icon: <TeamOutlined />,
      label: "Volunteer Members",
      link: "/admin/kdvs-members",
    },
    {
      key: "4",
      icon: <GlobalOutlined />,
      label: "Social Media Posts",
      link: "/admin/social-media-posts",
    },
    {
      key: "5",
      icon: <TrophyOutlined />,
      label: "Events",
      link: "/admin/events",
    },
    {
      key: "6",
      icon: <ScheduleOutlined />,
      label: "Jobs",
      link: "/admin/jobs",
    },
    {
      key: "7",
      icon: <ReadOutlined />,
      label: "Academic Batch",
      link: "/admin/academic-batchs",
    },
    {
      key: "8",
      icon: <SmileOutlined />,
      label: "Upcoming Birthday",
      link: "/admin/upcoming-birthday",
    },
    {
      key: "10",
      icon: <TeamOutlined />,
      label: "Data Master",
      link: "/admin/data-master",
    },
  ];

  return (
    <Layout className="layout">
      <Sider>
        <Header className="header">
          <div className="logo">
            <img
              src="/images/upb-logo.png"
              alt="KDVS logo"
              className="logo-img"
            />
          </div>
        </Header>
        <Menu
          theme="dark"
          mode="inline"
          items={menuItems}
          onClick={handleMenuClick}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="layout-header">
          <Button onClick={logoutHandler}>Logout</Button>
        </Header>
        <Content className="site-layout-background content-container">
          {children}
        </Content>
        <Footer className="footer-container">
          <span className="copy-text">
            &copy; {moment().format("YYYY")}{" "}
            <a
              href="https://kdvsgujarat.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              KDVS Gujarat
            </a>
            . {i18n.t(`All Rights Reserved`)} | Developed By{" "}
            <a
              href="http://mestrosolutions.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mestro Solutions
            </a>
          </span>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default connect(
  (state) => ({
    admin: state.adminInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(BaseLayout));
