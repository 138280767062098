import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { NavLink, withRouter } from "react-router-dom";
import { Button, Col, Input, Row, Table, Image, Popconfirm, Space } from "antd";
import { getFileUrl } from "../../common/helper";
import { DeleteOutlined } from "@ant-design/icons";
import { notifyError, notifySuccess } from "../../common/notification";
// import "./index.less";

const SocialMediaPostPage = (props) => {
  const { social_media_posts } = props;

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    props.listSocialMediaPost();
    // eslint-disable-next-line
  }, []);

  const handleFilter = () => {
    const params = {
      keyword: searchText,
    };

    props.listSocialMediaPost(params);
  };

  const handleDelete = async (id) => {
    const result = await props.removeSocialMediaPost(id);
    const { status, message } = result?.data;
    if (status) {
      notifySuccess(message);
      props.listSocialMediaPost();
      return;
    }

    notifyError(message);
  };

  const results = social_media_posts?.map((item, index) => {
    const { social_media_post_id } = item;
    return {
      ...item,
      key: social_media_post_id,
      sr_no: index + 1,
    };
  });

  const columns = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Title",
      dataIndex: "post_title",
      key: "post_title",
    },
    {
      title: "Image",
      dataIndex: "post_image_url",
      key: "post_image_url",
      render: (text, record) => (
        <>
          <Image
            src={getFileUrl(record?.post_image_url)}
            alt="post"
            width={100}
          />
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_text, record) => (
        <div className="btn-actions">
          <Space size="middle">
            <NavLink
              to={`/admin/social-media-post/${record.social_media_post_id}`}
            >
              Edit
            </NavLink>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => {
                handleDelete(record.social_media_post_id);
              }}
            >
              <Button type="primary" size="small" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <>
      <Row gutter={[10, 20]} className="filter-row">
        <Col>
          <h4>Social Media Posts</h4>
        </Col>
        <Col>
          <NavLink to="/admin/social-media-post/create">
            <Button type="primary">+ Create new Post</Button>
          </NavLink>
        </Col>
      </Row>

      <Row gutter={[10, 20]} className="filter-row">
        <Col>
          <Input
            defaultValue={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search"
          />
        </Col>
        <Col flex={1}></Col>
        <Col>
          <Button onClick={handleFilter}>Search</Button>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={columns}
        pagination={{
          position: "bottomRight",
          pageSize: 10,
          hideOnSinglePage: true,
        }}
      />
    </>
  );
};

export default connect(
  (state) => ({
    social_media_posts: state.social_media_posts,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(SocialMediaPostPage));
