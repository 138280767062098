// api status
export const make3 = (name) => [
  name + "_REQUEST",
  name + "_SUCCESS",
  name + "_FAIL",
];
// api status
export const loadmake3 = (name) => [
  name + "_START",
  name + "_COMPLETE",
  name + "_FAIL",
];
// actions for api calling
const promiseTypes = [
  "CHECK_AUTH_TOKEN",
  "GET_DISTRICT",
  "GET_TALUKA",
  "GET_CITY",
  "GET_WARD",
  "GET_SOCIETY",
  "LIST_CORE_MEMBER",
  "LIST_KDVS_MEMBER",
  "LIST_ACADEMIC_BATCHS",
  "LIST_SOCIAL_MEDIA_POSTS",
  "LIST_UPCOMING_BIRTHDAY",
  "LIST_EVENTS",
  "LIST_JOBS",
  "GET_DASHBOARD_STATISTICS",
].reduce((prev, cur) => {
  make3(cur).forEach((key) => {
    prev[key] = key;
  });

  return prev;
}, {});

// actions for api calling without loader
const loadPromiseTypes = ["LOAD_MORE_VIDEOS"].reduce((prev, cur) => {
  loadmake3(cur).forEach((key) => {
    prev[key] = key;
  });

  return prev;
}, {});

// actions for local data set
const simpleTypes = [
  "SET_ROUTE",
  "SET_ADMININFO",
  "CLEAR_DATA",
  "LOGOUT",
].reduce((prev, cur) => {
  prev[cur] = cur;
  return prev;
}, {});

export const types = { ...simpleTypes, ...promiseTypes, ...loadPromiseTypes };
