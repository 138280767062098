const dev = {
  API_URL: "http://localhost:4000",
  // API_URL: "https://api.kdvsgujarat.in",
  API_KEY: "6fcc423a-a171-4d0e-b7aa-9772be92e668",
};

const prod = {
  API_URL: "https://api.kdvsgujarat.in",
  API_KEY: "6fcc423a-a171-4d0e-b7aa-9772be92e668",
};

const { REACT_APP_ENV } = process.env;
const config = REACT_APP_ENV === "PROD" ? prod : dev;

// eslint-disable-next-line
export default {
  ...config,
};
