import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { NavLink, withRouter } from "react-router-dom";
import { Button, Col, Input, Row, Table, Popconfirm, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { notifyError, notifySuccess } from "../../common/notification";
import moment from "moment-timezone";
// import "./index.less";

const AcademicBatchsPage = (props) => {
  const { academic_batchs } = props;

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    const params = {};
    if (searchText) {
      params.keyword = searchText;
    }
    props.listAcademicBatch(params);
  };

  const handleFilter = () => {
    fetchData();
  };

  const handleDelete = async (id) => {
    const result = await props.removeAcademicBatch(id);
    const { status, message } = result?.data;
    if (status) {
      notifySuccess(message);
      fetchData();
      return;
    }

    notifyError(message);
  };

  const results = academic_batchs?.map((item, index) => {
    const { academic_batch_id } = item;
    return {
      ...item,
      key: academic_batch_id,
      sr_no: index + 1,
    };
  });

  const columns = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Name",
      dataIndex: "batch_name",
      key: "batch_name",
    },
    {
      title: "Start Date",
      dataIndex: "batch_start_date",
      key: "batch_start_date",
      render: (text, record) => (
        <>
          {!!record?.batch_start_date &&
            moment(record?.batch_start_date).format("DD MMM, YYYY")}
        </>
      ),
    },
    {
      title: "End Date",
      dataIndex: "batch_end_date",
      key: "batch_end_date",
      render: (text, record) => (
        <>
          {!!record?.batch_end_date &&
            moment(record?.batch_end_date).format("DD MMM, YYYY")}
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "batch_type",
      key: "batch_type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (_text, record) => (
        <div className="btn-actions">
          <Space size="middle">
            <NavLink to={`/admin/academic-batch/${record.academic_batch_id}`}>
              Edit
            </NavLink>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => {
                handleDelete(record.academic_batch_id);
              }}
            >
              <Button type="primary" size="small" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <>
      <Row gutter={[10, 20]} className="filter-row">
        <Col>
          <h4>Academic Batchs</h4>
        </Col>
        <Col>
          <NavLink to="/admin/academic-batch/create">
            <Button type="primary">+ Create new Academic Batch</Button>
          </NavLink>
        </Col>
      </Row>

      <Row gutter={[10, 20]} className="filter-row">
        <Col>
          <Input
            defaultValue={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search"
          />
        </Col>
        <Col flex={1}></Col>
        <Col>
          <Button onClick={handleFilter}>Search</Button>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={columns}
        pagination={{
          position: "bottomRight",
          pageSize: 10,
          hideOnSinglePage: true,
        }}
      />
    </>
  );
};

export default connect(
  (state) => ({
    academic_batchs: state.academic_batchs,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(AcademicBatchsPage));
