import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { useHistory, withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Upload,
  Checkbox,
  Select,
  DatePicker,
  Popconfirm,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  RequiredRule,
  MobileNumberRule,
  filterTalukas,
  filterWards,
  filterOption,
  getFileUrl,
  filterCities,
  filterParentDetails,
  EmailRule,
  filterSocieties,
} from "../../common/helper";
import moment from "moment-timezone";
import { notifyError, notifySuccess } from "../../common/notification";
import * as dayjs from "dayjs";
import { IsJsonString } from "../../common/utils";

const USER_ROLES = [
  { id: "core_committee", label: "Core Committee Member" },
  { id: "convener", label: "Convener" },
  { id: "sub_convener", label: "Sub Convener" },
];

const ManageCoreMemberPage = (props) => {
  const { id: memberId } = props?.match?.params;
  const { districts, talukas, cities, wards, societies } = props;

  const history = useHistory();
  const [state, setState] = useState({});
  const [error, setError] = useState("");
  const [fileImageList, setFileImageList] = useState([]);
  const formRef = useRef(null);

  const [districtId, setDistrictId] = useState(null);
  const [talukaId, setTalukaId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [wardId, setWardId] = useState(null);

  const [filteredTalukas, setFilteredTalukas] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredWards, setFilteredWards] = useState([]);
  const [filteredSocieties, setFilteredSocieties] = useState([]);

  const [userImage, setUserImage] = useState(null);

  const refetchData = () => {
    props.listDistrict();
    props.listTaluka();
    props.listCity();
    props.listWard();
    props.listSociety();
  };

  useEffect(() => {
    refetchData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (districtId) {
      setFilteredTalukas(filterTalukas(districtId, talukas));
    }
    // eslint-disable-next-line
  }, [districtId, talukas]);

  useEffect(() => {
    if (talukaId) {
      setFilteredCities(filterCities(talukaId, cities));
    }
    // eslint-disable-next-line
  }, [talukaId]);

  useEffect(() => {
    if (cityId) {
      setFilteredWards(filterWards(cityId, wards));
    }
    // eslint-disable-next-line
  }, [cityId]);

  useEffect(() => {
    if (wardId) {
      setFilteredSocieties(filterSocieties(wardId, societies));
    }
    // eslint-disable-next-line
  }, [wardId]);

  const whatsappSameAsMobile = (e) => {
    var mobileNumber = null;
    if (e.target.checked) {
      mobileNumber = formRef.current.getFieldValue("u_mobile_no");
    }
    formRef.current.setFieldsValue({
      whatsapp_no: mobileNumber || "",
    });
  };

  useEffect(() => {
    if (memberId) {
      fetchCoreMember(memberId);
    }
    // eslint-disable-next-line
  }, [memberId]);

  const fetchCoreMember = async (id) => {
    props.viewCoreMember(memberId).then((res) => {
      const { data, status, message } = res?.data;
      if (!status) {
        notifyError(message);
        history.push("/admin/core-members");
        return;
      }

      const formData = Object.assign({}, data);
      delete formData.date_of_birth;

      formRef.current.setFieldsValue({
        ...formData,
      });

      const {
        district_id,
        taluka_id,
        user_image,
        user_role,
        user_role_access,
      } = data;

      if (district_id) setDistrictId(district_id);
      if (taluka_id) setTalukaId(taluka_id);

      if (data.date_of_birth) {
        const dateOfBirth = dayjs(data.date_of_birth, "YYYY-MM-DD");
        formRef.current.setFieldsValue({
          date_of_birth: dateOfBirth,
        });
      }

      if (user_role_access && IsJsonString(user_role_access)) {
        const userRoleAccess = JSON.parse(user_role_access);
        const roleAccessObj = {
          user_role: user_role,
          is_all_access: userRoleAccess.is_all_access,
          is_specific_district_access:
            userRoleAccess.is_specific_district_access,
          is_specific_taluka_access: userRoleAccess.is_specific_taluka_access,
          is_specific_city_access: userRoleAccess.is_specific_city_access,
          is_specific_ward_access: userRoleAccess.is_specific_ward_access,
          is_specific_society_access: userRoleAccess.is_specific_society_access,
          role_district_id: parseInt(userRoleAccess?.district_id || 0),
          role_taluka_id: parseInt(userRoleAccess?.taluka_id || 0),
          role_city_id: parseInt(userRoleAccess?.city_id || 0),
          role_ward_id: parseInt(userRoleAccess?.ward_id || 0),
          role_society_id: parseInt(userRoleAccess?.society_id || 0),
        };
        formRef.current.setFieldsValue(roleAccessObj);
        setState({
          ...state,
          ...roleAccessObj,
        });
      }

      if (user_image) {
        const userImageUrl = getFileUrl(user_image);
        setFileImageList([
          {
            uid: "-1",
            status: "done",
            url: userImageUrl,
            name: "User image",
          },
        ]);
      }
    });
  };

  const imageUpload = (options) => {
    if (!options) {
      setUserImage(null);
      return;
    }
    if (options.file) {
      setUserImage(options.file);
    }

    options.onSuccess();
  };
  const checkAccessDisabled = (type) => {
    const {
      is_all_access,
      is_specific_district_access: isDistrictAccess,
      is_specific_taluka_access: isTalukaAccess,
      is_specific_city_access: isCityAccess,
      is_specific_ward_access: isWardAccess,
      is_specific_society_access: isSocietyAccess,
    } = state;
    switch (type) {
      case "district":
        return (
          is_all_access ||
          isTalukaAccess ||
          isCityAccess ||
          isWardAccess ||
          isSocietyAccess
        );
      case "taluka":
        return (
          is_all_access ||
          isDistrictAccess ||
          isCityAccess ||
          isWardAccess ||
          isSocietyAccess
        );
      case "city":
        return (
          is_all_access ||
          isDistrictAccess ||
          isTalukaAccess ||
          isWardAccess ||
          isSocietyAccess
        );
      case "ward":
        return (
          is_all_access ||
          isDistrictAccess ||
          isTalukaAccess ||
          isCityAccess ||
          isSocietyAccess
        );
      case "society":
        return (
          is_all_access ||
          isDistrictAccess ||
          isTalukaAccess ||
          isCityAccess ||
          isWardAccess
        );
      default:
        return true;
    }
  };

  const onFormSubmit = (postData) => {
    if (!userImage && !memberId) {
      setError("Please upload user image");
      return;
    }
    const { user_role, is_all_access } = postData;
    // if user_role is not core_committee and is_all_access is true then ask confirmation
    if (user_role !== "core_committee" && is_all_access) {
      const confirm = window.confirm(
        "Are you sure you want to give all access to this user?"
      );
      if (!confirm) return;
    }

    const formData = new FormData();

    Object.keys(postData).forEach((key) => {
      var value = postData?.[key] || "";
      if (key === "date_of_birth") {
        value = value.format("YYYY-MM-DD");
      }
      if (key === "interest_in_blood_donation") {
        value = value ? 1 : 0;
      }
      formData.append(key, value);
    });

    if (userImage) formData.append("user_image", userImage);

    let promise = memberId
      ? props.editCoreMember(memberId, formData)
      : props.saveCoreMember(formData);

    promise
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          props.history.push("/admin/core-members");
        } else {
          setError(message || "Error");
        }
      })
      .catch(() => {
        setError("Something went wrong");
      });
  };

  const resetUserPassword = async () => {
    if (!memberId) return;
    const res = await props.resetUserPassword(memberId);
    const { status, message } = res.data;
    if (!status) {
      notifyError(message);
      return;
    }

    notifySuccess(message);
  };

  const handleRoleChange = (value) => {
    if (value !== "core_committee") {
      formRef.current.setFieldsValue({
        is_all_access: false,
      });
      setState({
        ...state,
        user_role: value,
        is_all_access: false,
      });
    }
  };

  return (
    <div className="question-form">
      <Row>
        <Col flex={14}>
          <Form
            ref={formRef}
            layout="vertical"
            onValuesChange={(changedValues, allValues) => {
              setState(allValues);
            }}
            onFinish={onFormSubmit}
          >
            <Card>
              <Row className="flex">
                <Col flex={10}>
                  <h4>{memberId ? "Edit" : "Add"} core member</h4>
                </Col>
                <Col>
                  <Popconfirm
                    title="Are you sure to reset password?"
                    onConfirm={() => {
                      resetUserPassword();
                    }}
                  >
                    <Button type="primary">Reset Password</Button>
                  </Popconfirm>
                </Col>
              </Row>
              <hr />
              <Row gutter={[30, 20]}>
                <Col span={8}>
                  {fileImageList?.length > 0 && (
                    <Upload
                      customRequest={imageUpload}
                      onRemove={() => imageUpload(null)}
                      listType="picture"
                      maxCount={1}
                      defaultFileList={fileImageList}
                    >
                      <Button icon={<UploadOutlined />}>
                        Upload User Image
                      </Button>
                    </Upload>
                  )}
                  {fileImageList?.length === 0 && (
                    <Upload
                      customRequest={imageUpload}
                      onRemove={() => imageUpload(null)}
                      listType="picture"
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>
                        Upload User Image
                      </Button>
                    </Upload>
                  )}
                </Col>
              </Row>

              <Row gutter={[30, 20]}>
                <Col span={8}>
                  <Form.Item
                    name="surname"
                    label="Surname"
                    rules={[RequiredRule("Please enter surname")]}
                  >
                    <Input placeholder="Enter Surname" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="first_name"
                    label="First Name"
                    rules={[RequiredRule("Please enter first name")]}
                  >
                    <Input placeholder="Enter First name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="last_name"
                    label="Last Name"
                    rules={[RequiredRule("Please enter last name")]}
                  >
                    <Input placeholder="Enter Last name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[30, 20]}>
                <Col span={8}>
                  <Form.Item
                    name="u_mobile_no"
                    label="Mobile Number"
                    rules={[
                      RequiredRule("Please enter mobile number"),
                      MobileNumberRule("Please enter valid mobile number"),
                    ]}
                  >
                    <Input placeholder="Enter Mobile Number" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="whatsapp_no"
                    label="Whatsapp Number"
                    rules={[
                      RequiredRule("Please enter whatsapp number"),
                      MobileNumberRule("Please enter valid whatsapp number"),
                    ]}
                  >
                    <Input placeholder="Enter Whatsapp Number" />
                  </Form.Item>
                  <Checkbox onChange={whatsappSameAsMobile}>
                    Same as Mobile Number
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="u_email_id"
                    label="Email ID"
                    rules={[
                      RequiredRule("Please enter email id"),
                      EmailRule("Please enter valid email id"),
                    ]}
                  >
                    <Input placeholder="Enter email id" />
                  </Form.Item>
                </Col>
              </Row>

              <div style={{ marginTop: "20px" }}></div>

              <Row gutter={[30, 20]}>
                <Col span={8}>
                  <Form.Item
                    name="date_of_birth"
                    label="Date of Birth"
                    rules={[RequiredRule("Please select date of birth")]}
                  >
                    <DatePicker
                      placeholder="Select Date of Birth"
                      format={"DD MMM YYYY"}
                      disabledDate={(current) => current.isAfter(moment())}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="blood_group"
                    label="Blood Group"
                    rules={[RequiredRule("Please select Blood Group")]}
                  >
                    <Select placeholder="Select Blood Group">
                      <Select.Option value="A+">A+</Select.Option>
                      <Select.Option value="A-">A-</Select.Option>
                      <Select.Option value="B+">B+</Select.Option>
                      <Select.Option value="B-">B-</Select.Option>
                      <Select.Option value="AB+">AB+</Select.Option>
                      <Select.Option value="AB-">AB-</Select.Option>
                      <Select.Option value="O+">O+</Select.Option>
                      <Select.Option value="O-">O-</Select.Option>
                      <Select.Option value="Unknown">Not Sure</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="interest_in_blood_donation"
                    valuePropName="checked"
                  >
                    <Checkbox>Intrested in Blood Donation</Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="address"
                label="Current Address"
                rules={[RequiredRule("Please enter address")]}
              >
                <Input.TextArea placeholder="Enter address" rows={4} />
              </Form.Item>

              <Row
                style={{
                  marginBottom: "20px",
                }}
              >
                <Col span={6}>
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#1890ff",
                    }}
                    onClick={refetchData}
                  >
                    Refresh Locations
                  </span>
                </Col>
              </Row>
              <Row gutter={[10, 20]}>
                <Col span={6}>
                  <Form.Item
                    name="district_id"
                    label="District"
                    rules={[RequiredRule("Please select District")]}
                  >
                    <Select
                      placeholder="Select District"
                      showSearch
                      filterOption={filterOption}
                      onChange={(value) => setDistrictId(value)}
                    >
                      {districts?.map((item, index) => (
                        <Select.Option key={index} value={item.district_id}>
                          {item.district_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="taluka_id"
                    label="Taluka"
                    rules={[RequiredRule("Please select Taluka")]}
                  >
                    <Select
                      placeholder="Select Taluka"
                      showSearch
                      filterOption={filterOption}
                      onChange={(value) => setTalukaId(value)}
                    >
                      {filteredTalukas?.map((item, index) => (
                        <Select.Option key={index} value={item.taluka_id}>
                          {item.taluka_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="city_id"
                    label="City/Village"
                    rules={[RequiredRule("Please select City/Village")]}
                  >
                    <Select
                      placeholder="Select City/Village"
                      showSearch
                      filterOption={filterOption}
                      onChange={(value) => setCityId(value)}
                    >
                      {filteredCities?.map((item, index) => (
                        <Select.Option key={index} value={item.city_id}>
                          {item.city_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="ward_id" label="Ward (if any)">
                    <Select
                      placeholder="Select Ward"
                      showSearch
                      filterOption={filterOption}
                    >
                      {filteredWards?.map((item, index) => (
                        <Select.Option key={index} value={item.ward_id}>
                          {item.ward_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name="education_info" label="Education Info">
                <Input.TextArea placeholder="Enter education info" rows={2} />
              </Form.Item>

              <Form.Item name="occupation_info" label="Occupation Info">
                <Input.TextArea placeholder="Enter occupation info" rows={2} />
              </Form.Item>

              <Form.Item name="social_work_info" label="Social work Info">
                <Input.TextArea placeholder="Enter social work info" rows={2} />
              </Form.Item>
            </Card>

            <Card style={{ marginTop: 20 }}>
              <h4>User Access</h4>
              <hr />
              <Row gutter={[10, 20]}>
                <Col span={8}>
                  <Form.Item
                    name="user_role"
                    label="User Role"
                    rules={[RequiredRule("Please select User role")]}
                  >
                    <Select
                      placeholder="Select User role"
                      onChange={(val) => handleRoleChange(val)}
                    >
                      {USER_ROLES?.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[10, 20]}>
                <Col>
                  <Form.Item name="is_all_access" valuePropName="checked">
                    <Checkbox disabled={state?.user_role !== "core_committee"}>
                      All District & Taluka access
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row
                gutter={[10, 0]}
                className={state?.is_all_access && "d-none"}
              >
                <Col span={6}>
                  <Form.Item
                    name="is_specific_district_access"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={checkAccessDisabled("district")}>
                      District Convener
                    </Checkbox>
                  </Form.Item>

                  <Form.Item name="role_district_id" label="District (if any)">
                    <Select
                      placeholder="Select District"
                      showSearch
                      filterOption={filterOption}
                      disabled={!state?.is_specific_district_access}
                    >
                      {districts?.map((item, index) => (
                        <Select.Option key={index} value={item.district_id}>
                          {item.district_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    name="is_specific_taluka_access"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={checkAccessDisabled("taluka")}>
                      Taluka Convener
                    </Checkbox>
                  </Form.Item>

                  <Form.Item name="role_taluka_id" label="Taluka (if any)">
                    <Select
                      placeholder="Select Taluka"
                      showSearch
                      filterOption={filterOption}
                      disabled={!state?.is_specific_taluka_access}
                    >
                      {talukas?.map((item, index) => (
                        <Select.Option key={index} value={item.taluka_id}>
                          {item.taluka_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="address-list-items">
                    {filterParentDetails(
                      "taluka",
                      state?.role_taluka_id,
                      districts,
                      talukas
                    )}
                  </div>
                </Col>

                <Col span={6}>
                  <Form.Item
                    name="is_specific_city_access"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={checkAccessDisabled("city")}>
                      City/Village Convener
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="role_city_id" label="City/Village (if any)">
                    <Select
                      placeholder="Select City/Village"
                      showSearch
                      filterOption={filterOption}
                      disabled={!state?.is_specific_city_access}
                    >
                      {cities?.map((item, index) => (
                        <Select.Option key={index} value={item.city_id}>
                          {item.city_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="address-list-items">
                    {filterParentDetails(
                      "city",
                      state?.role_city_id,
                      districts,
                      talukas,
                      cities
                    )}
                  </div>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="is_specific_ward_access"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={checkAccessDisabled("ward")}>
                      Ward Convener
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="role_ward_id" label="Ward/Village (if any)">
                    <Select
                      placeholder="Select Ward/Village"
                      showSearch
                      filterOption={filterOption}
                      disabled={!state?.is_specific_ward_access}
                    >
                      {wards?.map((item, index) => (
                        <Select.Option key={index} value={item.ward_id}>
                          {item.ward_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="address-list-items">
                    {filterParentDetails(
                      "ward",
                      state?.role_ward_id,
                      districts,
                      talukas,
                      cities,
                      wards
                    )}
                  </div>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="is_specific_society_access"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={checkAccessDisabled("society")}>
                      Society Convener
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="role_society_id" label="Society (if any)">
                    <Select
                      placeholder="Select Society"
                      showSearch
                      filterOption={filterOption}
                      disabled={!state?.is_specific_society_access}
                    >
                      {societies?.map((item, index) => (
                        <Select.Option key={index} value={item.society_id}>
                          {item.society_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="address-list-items">
                    {filterParentDetails(
                      "society",
                      state?.role_society_id,
                      districts,
                      talukas,
                      cities,
                      wards,
                      societies
                    )}
                  </div>
                </Col>
              </Row>

              <Button htmlType="submit" type="primary">
                Save Data
              </Button>
              {error && (
                <Alert
                  message="Error"
                  description={error}
                  type="error"
                  closable
                  style={{ marginTop: "10px" }}
                  onClose={() => setError("")}
                />
              )}
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  (state) => ({
    districts: state.districts,
    talukas: state.talukas,
    cities: state.cities,
    wards: state.wards,
    societies: state.societies,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(ManageCoreMemberPage));
