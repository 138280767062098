import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider, createStore, reducer } from "./redux";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import * as serviceWorker from "./serviceWorker";

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
//console.log('Version ' + process.env.APP_VERSION)
const Router = BrowserRouter;
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Router>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#c23616",
          },
        }}
      >
        <App />
      </ConfigProvider>
    </Router>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
